import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide56/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide56/image/img.png"
import slider1 from "@components/pageGuide/guides/guide56/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide56/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide56/image/silder3.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
    ¿Cómo automatizar la venta de servicios educativos? 
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
    Descubre cómo automatizar la venta de servicios educativos y mejorar tu estrategia de negocio. La automatización es clave para hacer más eficiente la gestión de clientes y recursos, permitiéndote centrarte en lo importante: brindar una educación de calidad.   </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)

const textOne = (
  <p>
Conoce los pasos y herramientas necesarias para implementar un sistema que te permita gestionar inscripciones, pagos y comunicación con los estudiantes de manera fluida.  
<br /><br />
Esta guía te ofrece un recorrido por los aspectos fundamentales que debes considerar para optimizar tus procesos y mantener tu oferta educativa al día con las necesidades actuales.  </p>
)

const data = {
  start: {
    support: "Guía | Educación",
    title: title,
    description: textDescription,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Implementa un autoservicio con IA.",
      },
      {
        key: 2,
        text: "Agiliza el primer contacto con leads.",
      },
      {
        key: 3,
        text: "Realiza un seguimiento personalizado.",
      },
      {
        key: 4,
        text: "Notifica sobre ofertas.",
      },
      {
        key: 5,
        text: "Implementa workflow de proceso de matricula.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
