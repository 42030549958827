import React from "react"
import TemplatePageGuide  from "@components/pageGuide/template"
import { Helmet } from "react-helmet"
import data from "@components/pageGuide/guides/guide56/data"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>
        Como automatizar la venta de servicios educativos: Optimiza tu estrategia
       [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/recursos/guias/servicios-educativos/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Descubre cómo implementar la automatización en la venta de servicios educativos para mejorar eficiencia y resultados en tu empresa educativa."
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Como automatizar la venta de servicios educativos: Optimiza tu estrategia"
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/recursos/guias/servicios-educativos/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/metaimagenRecursos/Guias/og-imgGuia56.jpg`}
      />
      <meta
        property="og:description"
        content="Descubre cómo implementar la automatización en la venta de servicios educativos para mejorar eficiencia y resultados en tu empresa educativa."
      />
    </Helmet>
    <TemplatePageGuide
      ebook="Educación "
      location={location}
      typeGuide="guide56"
      clickHereHref="https://beexcc.com/blog/noticias-atencion-al-cliente/"
      data={data}
    />
  </div>
)

export default IndexPage
